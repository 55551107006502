import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Markdown from "../Markdown"

import "./Splash.scss"
import Logo from "../../images/logo-reversed.png"
import { TitleAppFr, TitleAppEn, IconFacebook, IconInstagram, IconTwitter } from "../Icons"

const Splash = ({ title, content, buttonTitle, lang}) => {
  const buttonLink = (lang === "fr")? "/instruments" : "/en/instruments"
  const appTitle = (lang === "fr")? <TitleAppFr /> : <TitleAppEn />

  return (
    <section className="splash">
      <div className="splash__container">
        <img className="splash__logo" src={Logo} alt="Orchestre Metropolitain"  />
        <div className="splash__tag">{appTitle}</div>
        <h1 className="splash__title">{title}</h1>
        <Markdown className="splash__content">{content}</Markdown>
        <Link to={buttonLink} className="splash__button">{buttonTitle}</Link>
        {(lang === "fr")? 
          <Link className="splash__lang" to="/en">English version</Link> :
          <Link className="splash__lang" to="/">Version française</Link>}

        <div className="splash__footer">
          {(lang === "fr")? 
            <Link className="splash__link" to="/credits">Crédits</Link> :
            <Link className="splash__link" to="/en/credits">Credits</Link>}
          {(lang === "fr")? 
            <a className="splash__link" href="https://orchestremetropolitain.com/fr/" target="_blank" rel="noreferrer">Site Web</a> :
            <a className="splash__link" href="https://orchestremetropolitain.com/en/" target="_blank" rel="noreferrer">Website</a>}
          <a className="splash__link splash__link--social" title="Facebook" href="https://www.facebook.com/orchestremetropolitain/" target="_blank" rel="noreferrer"><IconFacebook /></a>
          <a className="splash__link splash__link--social" title="Instagram" href="https://www.instagram.com/orchestre_metropolitain/"  target="_blank" rel="noreferrer"><IconInstagram /></a>
          <a className="splash__link splash__link--social" title="Tritter" href="https://twitter.com/LeMetropolitain" target="_blank" rel="noreferrer"><IconTwitter /></a>
        </div>
      </div>
    </section>
  )
}

Splash.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
}

export default Splash