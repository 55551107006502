import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Splash from "../components/Splash"

const IndexPage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <>
      <SEO 
        title={frontmatter.seo.title}
        description={frontmatter.seo.description}
        image={frontmatter.seo.image}
      />
      <Layout>
        <Splash
          title={frontmatter.headline}
          content={frontmatter.content}
          buttonTitle={frontmatter.button}
          lang={frontmatter.lang} />
      </Layout>
    </>
  )
}

export const indexQuery = graphql`
  query indexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        lang
        seo {
          title
          description
          image {
            publicURL
            relativePath
          }
        }
        headline
        content
        button
      }
    }
  }
`

export default IndexPage
